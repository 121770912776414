import { Box, ChakraProvider, Flex, Grid, Link, Text } from '@chakra-ui/react';
import react from 'react';
import { Helmet } from 'react-helmet';
import CtaFooter1 from './CtaFooter1';
import FooterNew from './FooterNew';
import NavComp from './NavComp';
// import './Styles.css';

export default function PrivacyPolicy() {
  return (
    <ChakraProvider>
      <Helmet>
        <title>ZinkML: Privacy Policy</title>
        <link rel="canonical" href="https://zinkml.com/privacy-policy" />
        <meta
          name="description"
          content="Please see ZinkML's Privacy Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta property="og:title" content="ZinkML: Privacy Policy" />
        <meta
          name="og:description"
          content="Please see ZinkML's Privacy Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="og:url" content="https://zinkml.com/privacy-policy" />

        <meta property="twitter:title" content="ZinkML: Privacy Policy" />
        <meta
          name="twitter:description"
          content="Please see ZinkML's Privacy Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="twitter:url" content="https://zinkml.com/privacy-policy" />
      </Helmet>
      <NavComp />
      <Flex
        id="homeTop"
        width="100vw"
        justify="center"
        paddingY="7rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
      >
        <Text
          // fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-5xl"
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(145deg, var(--amber-300), var(--purple-xx-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
        >
          Privacy Policy
        </Text>

        <Flex
          width="calc(min(1080px, 96%))"
          direction="column"
          margin="0 auto"
          gap="4rem"
        >
          <Grid
            templateColumns="repeat(1,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 12', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="3rem 1rem 3rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="#1a1e1f"
            >
              <Text className="para-text">
                Last Updated on 28th January, 2025
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                1. Introduction and Scope
              </Text>
              <Text className="para-text">
                This Privacy Policy describes how ZinkML Technologies Private
                Limited Company ("ZinkML," "we," "us," or "our"), headquartered
                at WeWork Galaxy, #43, Residency Road, Bengaluru, India - 560025
                collects, uses, discloses, and protects information obtained
                through our machine learning platform, website, APIs, and
                associated services (collectively, the "Services"). We are
                committed to protecting your privacy and handling your data with
                transparency and care. By accessing or using our Services, you
                acknowledge that you have read and understood this Privacy
                Policy and agree to the collection and use of information as
                described herein.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                2. Information Collection and Processing
              </Text>
              <Text className="para-text">
                We collect several categories of information through your use of
                our Services. Our primary purpose in collecting this information
                is to provide and improve our machine learning platform, ensure
                the security of our Services, and enhance your user experience.
                The information we collect includes both data you directly
                provide to us and data we automatically gather through your
                interaction with our Services.
              </Text>
              <Text className="para-text">
                When you create an account and use our Services, we collect
                information that personally identifies you ("Personal
                Information"). This includes your name, email address, phone
                number, company affiliation, job title, and billing
                information(if applicable). We also collect any additional
                information you choose to provide when communicating with our
                support team, participating in our events or webinars, or
                subscribing to our newsletters and updates. Your authentication
                credentials, such as usernames and password hashes, are securely
                stored and used to protect access to your account.
              </Text>
              <Text className="para-text">
                During your use of our platform, we automatically collect
                technical data about your interactions with our Services ("Usage
                Data"). This includes log data, IP addresses, browser type and
                settings, device information, operating system details, and
                service performance metrics. We track API calls, request
                patterns, and platform utilization statistics to maintain and
                improve our Services. This technical data helps us understand
                how our Services are used, identify and resolve issues, and
                optimize platform performance.
              </Text>
              <Text className="para-text">
                We use cookies and similar technologies to collect information
                about your browsing activities. We may also combine this
                information collected automatically with other data we receive
                from third-party sources, such as data providers and marketing
                partners, to create a more complete profile of you. You can
                manage your cookie preferences through your browser settings.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                3. Data Processing and Customer Content
              </Text>
              <Text className="para-text">
                When you upload datasets, create machine learning models, or
                generate predictions through our Services ("Customer Data"), we
                process this information as necessary to deliver our contracted
                services. This processing may include data storage, computation,
                model training, and inference operations. We maintain strict
                controls over Customer Data and process it only in accordance
                with your instructions and our service agreements.
              </Text>
              <Text className="para-text">
                We implement comprehensive security measures to protect all data
                processed through our platform. These measures include
                encryption of data both in transit and at rest, robust access
                controls, regular security assessments, and continuous
                monitoring for unauthorized access attempts. Our employees
                receive regular security training and are bound by strict
                confidentiality obligations regarding your data.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                4. Use of Collected Information
              </Text>
              <Text className="para-text">
                Our use of collected information is focused on providing and
                improving our Services. We process your Personal Information and
                Usage Data to authenticate users, manage accounts, process
                payments, and provide technical support. This information also
                helps us analyze service usage patterns, develop new features,
                and maintain platform security. We may use aggregated,
                anonymized data to improve our machine learning algorithms and
                platform capabilities, but this never includes processing
                Customer Data without explicit authorization.
              </Text>
              <Text className="para-text">
                We communicate with users about service updates, technical
                issues, and platform maintenance. With your consent, we may send
                you educational content, product updates, and information about
                events or new features. You can control these communications
                through your account settings or by following the unsubscribe
                instructions included in our messages.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                5. Data Storage, Security, and Retention
              </Text>
              <Text className="para-text">
                We maintain comprehensive security protocols, including
                encryption, access controls, network monitoring, and incident
                response procedures. For enterprise customers with specific data
                residency requirements, we offer regional storage options as
                detailed in individual service agreements.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                6. Data Retention and Deletion Practices
              </Text>
              <Text className="para-text">
                We retain your information for the duration necessary to fulfill
                the purposes outlined in this Privacy Policy, unless a longer
                retention period is required by law or specified in your service
                agreement. For active accounts, this typically means we keep
                your Personal Information and Usage Data for the lifetime of
                your account. Customer Data is retained according to the terms
                of your service agreement and any applicable data retention
                policies you've established.
              </Text>
              <Text className="para-text">
                When you delete your account, we initiate a process to securely
                remove your Personal Information from our active systems.
                However, we may retain certain information in our backup systems
                for a limited period to comply with legal obligations, resolve
                disputes, or enforce our agreements. Customer Data is handled
                according to the termination provisions in your service
                agreement, typically including a defined period for you to
                export your data before deletion.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                7. Information Sharing and Third-Party Relationships
              </Text>
              <Text className="para-text">
                We understand the sensitivity of your information and maintain
                strict controls over how it is shared. We do not sell, rent, or
                trade your Personal Information or Customer Data to third
                parties for their marketing purposes. However, we may share your
                information in limited circumstances as described below.
              </Text>
              <Text className="para-text">
                We engage trusted service providers to help us operate our
                platform, process payments, provide customer support, and
                analyze Service usage. These service providers are contractually
                bound to protect your information and may only use it for the
                specific services they provide on our behalf. Examples include
                cloud infrastructure providers, payment processors, and
                analytics services. We regularly review our service providers'
                security practices and require them to comply with our data
                protection standards.
              </Text>
              <Text className="para-text">
                In some cases, we may be required to disclose information in
                response to valid legal requests, such as court orders or
                government regulations. We evaluate each such request carefully
                and only disclose information to the extent legally required. If
                permitted, we will notify you of any such requests affecting
                your data. We may also share information if we believe it
                necessary to protect our rights, prevent fraud or abuse, or
                ensure the security of our platform.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                8. User Rights and Privacy Controls
              </Text>
              <Text className="para-text">
                We are committed to providing you with meaningful control over
                your information. You have the right to access, correct, or
                delete your Personal Information through your account settings
                or by contacting our privacy team. For Customer Data, these
                actions are governed by your service agreement and can be
                executed through our platform's administrative interfaces or
                with the assistance of our support team.
              </Text>
              <Text className="para-text">
                For users in jurisdictions with specific data protection
                regulations (such as GDPR or CCPA), we provide additional rights
                and controls. These may include the right to object to certain
                types of processing, request data portability, restrict
                processing, correct/delete data or withdraw consent. We have
                implemented processes to honor these rights and respond to
                requests within legally required timeframes.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                9. International Data Transfers and Compliance
              </Text>
              <Text className="para-text">
                As a global platform, we may transfer your information to
                countries other than your country of residence. When we do so,
                we implement appropriate safeguards to ensure your information
                receives consistent protection. These safeguards include:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>
                    Standard contractual clauses approved by relevant data
                    protection authorities
                  </li>
                  <li>
                    Data processing agreements with our service providers and
                    sub-processors
                  </li>
                  <li>
                    Regional data storage options for customers with specific
                    geographical requirements
                  </li>
                  <li>
                    Compliance certifications and regular audits of our privacy
                    practices
                  </li>
                </ul>
              </Text>
              <Text className="para-text">
                For transfers from the European Economic Area, we maintain
                appropriate data transfer mechanisms and supplementary measures
                as required by applicable data protection laws. We monitor
                changes in international data protection regulations and update
                our practices accordingly.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                10. Changes to Privacy Policy and Communications
              </Text>
              <Text className="para-text">
                We may update this Privacy Policy periodically to reflect
                changes in our practices, technologies, or legal requirements.
                Material changes will be communicated through our website, email
                notifications, or platform announcements. Your continued use of
                our Services after such changes constitutes acceptance of the
                updated policy.
              </Text>
              <Text className="para-text">
                We encourage you to review this Privacy Policy regularly to stay
                informed about our privacy practices. Historical versions of our
                Privacy Policy are available upon request. If you disagree with
                any changes, you may close your account as specified in your
                service agreement.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                11. Contact Information and Support
              </Text>
              <Text className="para-text">
                We welcome your questions, comments, and concerns about privacy.
                Our dedicated privacy team can be reached through multiple
                channels:
              </Text>
              <Text className="para-text">
                Privacy Officer
                <br />
                Zinkml Technologies Private Limited Company
                <br />
                WeWork Galaxy, #43, Residency Road, Bengaluru, India - 560025
                <br />
                Email: rajeev@zinkml.com
                <br />
                Phone: +91 80 30712516
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                12. Commitment to Privacy Protection
              </Text>
              <Text className="para-text">
                Our commitment to privacy extends beyond mere compliance with
                laws and regulations. We strive to maintain the trust of our
                users by being transparent about our data practices and
                providing robust privacy protections. We regularly review and
                update our privacy practices to address emerging threats and
                evolving best practices in data protection.
              </Text>
              <Text className="para-text">
                This Privacy Policy was last updated on January 28th, 2025 and
                replaces any prior versions. Your privacy is important to us,
                and we are dedicated to protecting your information as we
                continue to develop and expand our Services.
              </Text>
              <Text className="para-text">
                Please note that this Privacy Policy should be read in
                conjunction with our Terms of Service and any additional
                agreements or policies referenced therein. If you have specific
                privacy requirements or need clarification about any aspect of
                this policy, we encourage you to contact our privacy team for
                assistance.
              </Text>
            </Flex>
          </Grid>
        </Flex>
      </Flex>
      <CtaFooter1 />
      <FooterNew />
    </ChakraProvider>
  );
}
