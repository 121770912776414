import { NextOutline } from '@carbon/icons-react';
import { Box, ChakraProvider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import CtaButton from './CtaButton';
// import TestPage from './HomeHeaderSlider';
// import HomeHeaderBackground from './HomeHeaderBackground.svg';
let ctaIcon = <NextOutline color="var(--teal-300)" size="16" />;
function HomeHeaderNew() {
  return (
    <ChakraProvider>
      <Flex
        id="homeTop"
        className="observer1"
        data-id="1"
        width="100vw"
        paddingTop={['7rem', '7rem', '6rem', '6rem']}
        justifyContent="center"
      >
        <Flex
          width="100%"
          maxWidth="1280px"
          paddingY={['4', '12', '16', '16']}
          justifyContent="center"
          grow="1"
          paddingX={['2', '2', '5']}
        >
          <Flex
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            position="relative"
            // bg="var(--chart-bg1)"
            bg="linear-gradient(145deg, #222026, #161c29)"
            borderRadius="20px"
            // borderTopRadius={['0', '10px', '20px', '30px', '30px']}
            borderTop="1px solid var(--slate-800)"
            // width={['100%', '95%', '95%', '95%', '95%']}
            width="100%"
            overflow="hidden"
            flexDir={{
              base: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
              '2xl': 'row',
            }}
            padding={{
              base: '20px 10px 20px',
              md: '40px 15px 40px',
              lg: '100px 20px 100px',
              xl: '100px 20px 100px',
              '2xl': '100px 20px 100px',
            }}
            justifyContent="flex-start"
            gap={{
              base: '40px',
              md: '20px',
              lg: '20px',
              xl: '20px',
              '2xl': '20px',
            }}
          >
            {/* <BetaHeader /> */}
            <Flex
              direction="column"
              grow="1"
              justify="flex-start"
              padding="36px 0 36px"
              maxWidth="98%"
              margin="0 auto"
            >
              <Box paddingBottom="10px">
                <Text
                  fontSize={['32px', '32px', '2.5rem', '3rem', '3rem']}
                  lineHeight="1.4"
                  className="text-5xl"
                  fontWeight="600"
                  letterSpacing="-.015em"
                  textShadow="0 1px #00000029"
                  bgGradient="linear-gradient(to bottom, var(--orange-200), var(--sky-400))"
                  bgClip="text"
                  transition="all ease-in-out 300ms"
                  textAlign="center"
                  width="max-content"
                  maxWidth="100%"
                  margin="auto"
                  _hover={{
                    brightness: '150%',
                    // transform: 'translatez(10px) scale(1.02)',
                    transitionDuration: '0.5s',
                    opacity: '1',
                  }}
                >
                  Unlock Data Science with Zero Code: <br />
                  Your End-to-End Solution
                </Text>
              </Box>
              <Box
                className="smallDivider"
                width="calc(min(300px, 90%))"
                margin="16px auto"
                bg="linear-gradient(45deg, var(--slate-900), var(--slate-500), var(--slate-900))"
              ></Box>
              <Box
                paddingTop="10px"
                paddingBottom="10px"
                // className="textBox"  Used for anime effects
                // position="relative"
              >
                <Text
                  // className="text-wrapper"
                  fontSize={['14px', '16px', '16px', '16px', '16px']}
                  lineHeight="1.67"
                  // color="var(--teal-400)"
                  textAlign="center"
                  fontWeight="400"
                  color="var(--slate-300)"
                  letterSpacing="0.02em"
                  // position="relative"
                  // overflow="hidden"
                >
                  {/* <Text className="letters"> */}
                  Seamless Ingestion.&nbsp; Effortless Processing.&nbsp; Easy
                  Deployment.
                  {/* And so much more !</Text> */}
                  {/* Clean, transform, visualize datasets; */}
                </Text>
              </Box>
              {/* <Box
                className="smallDivider"
                width="25px"
                color="myTertiary.500"
              ></Box> */}
              <Flex
                color="#d0d0d0"
                _hover={{ color: '#ffffff' }}
                _focus={{ boxShadow: 'none' }}
                // href="/"
                className="test-xs"
                justifyContent="center"
                // fontSize="12px"
                // lineHeight="3.7"
                paddingTop="15px"
              >
                <CtaButton
                  text="Try our App"
                  link="https://app.zinkml.com/signup?zm=1"
                  icon={ctaIcon}
                  width="154px"
                  borderRadius="var(--round-full)"
                  target="_self"
                  signup="true"
                />
                {/* <Box className="smallDivider" width='10px' color="myTertiary.500"></Box> */}
              </Flex>
            </Flex>
            {/* <Flex
              width="100%"
              maxWidth="325px"
              grow={[1, 1, 0, 0, 0]}
              // bg="#f63e54"
              height="500px"
              // borderRadius="30px"
              // padding={['10px', '15px', '20px', '20px', '20px']}
              justify="center"
              alignItems="center"
              alignSelf="center"
              alignContent="center"
            > */}
            {/* <Text fontSize="56px" fontWeight="600">
                Connect
              </Text> */}
            {/* <TestPage /> */}
            {/* </Flex> */}
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default HomeHeaderNew;
