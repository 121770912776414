import {
  LocationCompany,
  LogoGithub,
  LogoLinkedin,
  LogoX,
  LogoYoutube,
} from '@carbon/icons-react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
// import { Link } from 'react-router-dom';

export const links = [
  {
    heading: { text: 'Home', link: '/' },
    subHeadings: [
      { text: 'Overview', link: '/#homeTop' },
      { text: 'Toolkit', link: '/#ProductFlow' },
      { text: 'USPs', link: '/#USPs' },
      { text: 'Features', link: '/#ProductComponents' },
      { text: 'Book a Demo', link: '/request-demo' },
      { text: 'Queries', link: '/feedback-query' },
    ],
  },
  // {
  //   heading: { text: 'Product', link: '/' },
  //   subHeadings: [
  //     { text: 'Overview', link: '/#homeTop' },
  //     { text: 'Product in Images', link: '/#imageSliders' },
  //     { text: 'USPs', link: '/#USPs' },
  //     { text: 'Product Flow', link: '/#ProductFlow' },
  //     { text: 'Product Components', link: '/#ProductComponents' },
  //     { text: 'Overview', link: '/#homeTop' },
  //     { text: 'Product in Images', link: '/#imageSliders' },
  //     { text: 'USPs', link: '/#USPs' },
  //   ],
  // },
  // {
  //   heading: { text: 'Why Us', link: '/' },
  //   subHeadings: [
  //     { text: 'Overview', link: '/#homeTop' },
  //     { text: 'Product in Images', link: '/#imageSliders' },
  //     { text: 'USPs', link: '/#USPs' },
  //     { text: 'Product Flow', link: '/#ProductFlow' },
  //     { text: 'Product Components', link: '/#ProductComponents' },
  //   ],
  // },
  // {
  //   heading: { text: 'Pricing', link: '/' },
  //   subHeadings: [
  //     { text: 'Overview', link: '/#homeTop' },
  //     { text: 'Product in Images', link: '/#imageSliders' },
  //     { text: 'USPs', link: '/#USPs' },
  //   ],
  // },
  {
    heading: { text: 'About Us', link: '/about-us' },
    subHeadings: [
      { text: 'Mission', link: '/about-us' },
      { text: 'Our Team', link: '/about-us#team' },
      { text: 'Careers', link: '/about-us#careers' },
      { text: 'Contact Us', link: '/about-us#contactUs' },
      { text: 'Terms of Use', link: '/terms-of-use' },
      { text: 'Privacy Policy', link: '/privacy-policy' },
    ],
  },
  {
    heading: { text: 'Help' },
    subHeadings: [
      {
        text: 'Docs',
        link: 'https://docs.zinkml.com',
        target: '_blank',
      },
      {
        text: 'Support',
        link: 'https://app.zinkml.com?query=true',
        target: '_blank',
      },
      { text: 'Blog', link: 'https://blog.zinkml.com', target: '_blank' },
      {
        text: 'Email',
        link: 'mailto:support@zinkml.com?subject=General%20Enquiry&body=My%20query%20is..',
      },
    ],
  },
];

function FooterNew() {
  return (
    <Flex
      width="100vw"
      justifyContent="center"
      // className="bgTop"
      paddingTop="40px"
      paddingX="5"
      flexDir="column"
      bg="linear-gradient(to bottom, var(--bg-default), #202422)"
      // bg="transparent"
      // margin="0 auto"
    >
      <Flex
        maxWidth="1280px"
        // paddingBottom={['40px', '60px', '80px']}
        width="100%"
        justifyContent="center"
        alignItems="center"
        // boxShadow="dark-lg"
        paddingY="12"
        paddingX="5"
        // bg="var(--chart-bg1)"
        // bg="blackAlpha.900"
        // borderRadius={['10px', '10px', '20px', '20px', '20px']}
        // border="1px solid var(--slate-800)"
        direction="column"
        margin="0 auto"
      >
        <Flex
          width="fit-content"
          justifyContent="space-between"
          // padding={['25px 25px', '25px 55px']}
          // gap={['12', '24']}
          gap={('5px', '20px')}
          grow="1"
          paddingBottom="12"
          flexDirection={['column', 'row']}
          // paddingBottom={['40px', '40px', '40px']}
        >
          {links.map(el => {
            return (
              <Flex direction="column">
                <Text
                  as="a"
                  href={el.heading.link}
                  className="text-xs"
                  fontWeight="bold"
                  paddingY="10px"
                  textDecoration="underline var(--slate-400)"
                  textUnderlineOffset="8px"
                  // paddingBottom=".8em"
                >
                  {el.heading.text}
                </Text>
                {el.subHeadings.map(ele => {
                  return (
                    <Link
                      href={ele.link}
                      target={ele.target ? ele.target : '_self'}
                    >
                      <Text
                        fontSize="0.8rem"
                        fontWeight="500"
                        paddingY="3px"
                        letterSpacing="0.02em"
                        color="var(--slate-200)"
                        _hover={{
                          textDecoration: 'underline',
                          color: 'var(--teal-300)',
                        }}
                      >
                        {ele.text}
                      </Text>
                    </Link>
                  );
                })}
              </Flex>
            );
          })}
        </Flex>
        <Box
          className="smallDivider"
          width="calc(min(300px, 90%))"
          margin="16px auto"
          bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
        ></Box>
        <Flex alignSelf="center" gap="10px" alignItems="center">
          <Link
            href="https://www.linkedin.com/company/zinkml"
            target="_blank"
            color="var(--slate-300)"
            _hover={{
              color: 'var(--sky-400)',
            }}
          >
            <LogoLinkedin size="32" />
          </Link>
          <Link
            href="https://x.com/ZinkmlOfficial"
            target="_blank"
            color="var(--slate-300)"
            _hover={{
              color: 'var(--slate-50)',
            }}
          >
            <LogoX size="32" />
          </Link>
          <Link
            href="https://www.youtube.com/@ZinkMLOfficial"
            target="_blank"
            color="var(--slate-300)"
            _hover={{
              color: 'rgb(234, 51, 35)',
            }}
          >
            <LogoYoutube size="32" />
          </Link>
          <Link
            href="https://github.com/zinkml"
            target="_blank"
            color="var(--slate-300)"
            _hover={{
              color: 'var(--slate-50)',
            }}
          >
            <LogoGithub size="32" />
          </Link>
        </Flex>
        <Box
          className="smallDivider"
          width="calc(min(300px, 90%))"
          margin="16px auto"
          bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
        ></Box>
        <Flex alignSelf="center" gap="10px" alignItems="center">
          <LocationCompany size="24" fill="var(--pink-400)" />
          <Text className="text-xs">
            WeWork Galaxy, #43, Residency Road, Bangalore - 560025
          </Text>
        </Flex>

        {/* <Flex gap="5" alignSelf="center">
          <Text className="text-xs">Privacy Policy</Text>
          <Text className="text-xs">Terms of Use</Text>
          <Text className="text-xs">Privacy Policy</Text>
        </Flex> */}
      </Flex>
      <Box
        className="text-xs"
        color="var(--slate-400)"
        // bg="var(--bg-5)"
        width="100%"
        textAlign="center"
        padding="3px"
        borderTop="1px solid var(--slate-800)"
      >
        © 2025, ZinkML Technologies Private Limited or its affiliates. All
        rights reserved.
        <Link
          href="/terms-of-use"
          paddingX="10px"
          color="var(--slate-300)"
          _hover={{
            color: 'var(--purple-300)',
          }}
        >
          Terms of Use
        </Link>
        <Link
          href="/privacy-policy"
          paddingX="10px"
          color="var(--slate-300)"
          _hover={{
            color: 'var(--purple-300)',
          }}
        >
          Privacy policy
        </Link>
      </Box>
    </Flex>
  );
}

export default FooterNew;
