import { Box, ChakraProvider, Flex, Grid, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import CtaFooter1 from './CtaFooter1';
import FooterNew from './FooterNew';
import NavComp from './NavComp';
// import './Styles.css';

export default function CookiePolicy() {
  return (
    <ChakraProvider>
      <Helmet>
        <title>ZinkML: Cookie Policy</title>
        <link rel="canonical" href="https://zinkml.com/cookie-policy" />
        <meta
          name="description"
          content="Please see ZinkML's Cookie Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta property="og:title" content="ZinkML: Cookie Policy" />
        <meta
          name="og:description"
          content="Please see ZinkML's Cookie Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="og:url" content="https://zinkml.com/cookie-policy" />

        <meta property="twitter:title" content="ZinkML: Cookie Policy" />
        <meta
          name="twitter:description"
          content="Please see ZinkML's Cookie Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="twitter:url" content="https://zinkml.com/cookie-policy" />
      </Helmet>
      <NavComp />
      <Flex
        id="homeTop"
        width="100vw"
        justify="center"
        paddingY="7rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
      >
        <Text
          // fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-5xl"
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(145deg, var(--amber-300), var(--purple-xx-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
        >
          Cookie Policy
        </Text>

        <Flex
          width="calc(min(1080px, 96%))"
          direction="column"
          margin="0 auto"
          gap="4rem"
        >
          <Grid
            templateColumns="repeat(1,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 12', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="3rem 1rem 3rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="#1a1e1f"
            >
              <Text className="para-text">Last Updated: 15th March, 2024</Text>
              <Text className="para-text">
                This cookie policy explains how ZinkML Technologies Private
                Limited Company ("we," "our," or "us") uses cookies and similar
                tracking technologies when you visit our website and use our
                services.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                What are Cookies?
              </Text>
              <Text className="para-text">
                Cookies are small text files that are stored on your device
                (computer, smartphone, tablet) when you visit a website. They
                are widely used to make websites work more efficiently, as well
                as to provide information to the owners of the site.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                How We Use Cookies
              </Text>
              <Text className="para-text">
                We use cookies for the following purposes:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>
                    Essential Cookies: These cookies are necessary for the
                    functioning of our website and services. They enable you to
                    navigate our site and use its features, such as accessing
                    secure areas.
                  </li>
                  <li>
                    Performance Cookies: These cookies allow us to monitor the
                    performance of our website, including how visitors interact
                    with our site, the pages they visit most frequently, and any
                    error messages they encounter. This helps us improve the
                    performance and usability of our website.
                  </li>
                  <li>
                    Marketing Cookies: We may use marketing cookies to track
                    user behavior across our website and personalize marketing
                    content based on your interests. These cookies may also be
                    used to provide targeted advertisements on third-party
                    websites.
                  </li>
                  <li>
                    Functionality Cookies: Functionality cookies are used to
                    remember choices you make (such as language preferences) and
                    provide enhanced, more personalized features.
                  </li>
                </ul>
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Third-Party Cookies
              </Text>
              <Text className="para-text">
                Some of our cookies may be placed by third-party service
                providers that perform services on our behalf, such as analytics
                and marketing companies. These third parties may also collect
                information about your online activities over time and across
                different websites.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Updates to this Cookie Policy
              </Text>
              <Text className="para-text">
                We may update this Cookie Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. We encourage you to review this policy
                periodically for any updates.
              </Text>

              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--slate-500), var(--slate-300))"
                bgClip="text"
                paddingBottom="6px"
              >
                Contact Us
              </Text>
              <Text className="para-text">
                If you have any questions or concerns about this Cookie Policy
                or our data practices, please contact us at support@zinkml.com.
              </Text>
              <Text className="para-text">
                Thank you for choosing ZinkML Technologies Private Limited
                Company. We are dedicated to protecting your privacy and
                ensuring the security of your personal information as we
                continue to deliver global data science solutions.
              </Text>
            </Flex>
          </Grid>
        </Flex>
      </Flex>
      <CtaFooter1 />
      <FooterNew />
    </ChakraProvider>
  );
}
