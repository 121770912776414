import { ChakraProvider, Flex, Grid, Link, Text } from '@chakra-ui/react';

export default function TermsOfUseConsumer() {
  return (
    <ChakraProvider>
      <Flex
        width="calc(min(1080px, 96%))"
        direction="column"
        margin="0 auto"
        gap="4rem"
      >
        <Grid
          templateColumns="repeat(1,minmax(0,1fr))"
          gap="0"
          // paddingY="2rem"
          // paddingX="5"
          padding="0"
          width="100%"
          boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
          borderTop="1px solid var(--slate-800)"
          borderRadius="var(--round-xxl)"
          overflow="hidden"
        >
          <Flex
            gridColumn={['span 12', 'span 12', 'span 9']}
            direction="column"
            justify="center"
            // paddingX="5"
            // paddingY="10"
            padding="3rem 1rem 3rem 2rem"
            gap="5px"
            // maxWidth="450px"
            bg="#1a1e1f"
          >
            <Text className="para-text">
              Consumer Terms of Use: Last Updated on 28th January, 2025
            </Text>
            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              1. Introduction and Overview
            </Text>
            <Text className="para-text">
              Welcome to ZinkML Technologies Private Limited Company. These
              Terms of Use ("Terms") constitute a legally binding agreement
              between you and ZinkML Technologies Private Limited Company
              ("ZinkML," "we," "us," or "our"), headquartered at WeWork Galaxy,
              #43, Residency Road, Bengaluru, India - 560025. These Terms govern
              your access to and use of our website, platform, features, tools,
              and services (collectively, the "Services"), including our machine
              learning model development and deployment capabilities, data
              processing functionalities, and any associated applications or
              interfaces.
            </Text>
            <Text className="para-text">
              By accessing or using our Services, you acknowledge that you have
              read, understood, and agree to be bound by these Terms. If you do
              not agree with any part of these Terms, you must not use our
              Services. We may modify these Terms at any time by posting updated
              Terms on our website. Your continued use of the Services after
              such modifications constitutes your acceptance of the updated
              Terms.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              2. Account Registration and Security
            </Text>
            <Text className="para-text">
              To access our Services, you must be at least 18 years old and
              create an account by providing accurate, current, and complete
              information. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account. We reserve the right to refuse
              registration, terminate accounts, or remove content at our
              discretion.
            </Text>
            <Text className="para-text">
              You agree to notify us immediately of any unauthorized access to
              your account or any other breach of security. We will not be
              liable for any loss or damage arising from your failure to protect
              your account credentials. You may not share your account
              credentials with any third party or allow others to access the
              Services through your account.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              3. Data Rights and Ownership
            </Text>
            <Text className="para-text">
              When you upload data to our Services ("User Data"), you retain all
              ownership rights to such data. By using our Services, you grant us
              a limited, non-exclusive, royalty-free license to process, store,
              and use your User Data solely to the extent necessary to provide
              and improve our Services. This license terminates when you remove
              your User Data from our Services or close your account, except
              where we are required by law to retain such data.
            </Text>
            <Text className="para-text">
              You represent and warrant that you have all necessary rights,
              licenses, and permissions to upload and process your User Data
              using our Services, and that such use will not violate any laws or
              infringe upon any third-party rights. You are solely responsible
              for ensuring your use of our Services complies with applicable
              data protection and privacy laws.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              4. Dataflows and Outputs
            </Text>
            <Text className="para-text">
              Any outputs or results generated through your use of our Services
              ("Outputs") belong to you, subject to our underlying intellectual
              property rights in the Services. You may use, modify, and
              distribute such Outputs for any lawful purpose, provided you
              maintain all applicable attributions and comply with these Terms.
            </Text>
            <Text className="para-text">
              While you own your Outputs, we retain all rights, title, and
              interest in our Services, including our platform technology,
              algorithms, and any improvements or modifications made to our
              Services, regardless of whether such improvements were informed by
              your use of the Services.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              5. Privacy and Data Protection
            </Text>
            <Text className="para-text">
              We take your privacy seriously and handle your personal
              information in accordance with our &nbsp;
              <Link href="/privacy-policy" color="blue.400">
                Privacy Policy
              </Link>
              . We implement reasonable security measures designed to protect
              your data from unauthorized access, disclosure, or destruction.
              However, no security system is impenetrable, and we cannot
              guarantee the security of your information.
            </Text>
            <Text className="para-text">
              We may collect and analyze anonymized, aggregated data about your
              use of our Services to improve our platform and develop new
              features. Such anonymized data will not identify you or your
              organization and will be handled in accordance with our Privacy
              Policy.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              6. Acceptable Use and Restrictions
            </Text>
            <Text className="para-text">
              You agree to use our Services only for lawful purposes and in
              accordance with these Terms. You may use our Services to create
              and deploy machine learning models, process authorized datasets,
              and access platform features included in your subscription. You
              are responsible for all activities conducted through your account
              and must comply with all applicable laws and regulations.
            </Text>
            <Text className="para-text">
              Prohibited activities include, but are not limited to:
            </Text>
            <Text className="para-text" paddingLeft="1rem">
              <ul>
                <li>Violating any applicable laws or regulations</li>
                <li>Infringing upon intellectual property rights</li>
                <li>Attempting to reverse engineer the Services</li>
                <li>Sharing account access with unauthorized users</li>
                <li>Exceeding usage limits specified in your subscription</li>
                <li>Using the Services for malicious purposes</li>
                <li>Attempting to breach or circumvent security measures</li>
                <li>Interfering with other users' access to the Services</li>
              </ul>
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              7. Fees, Billing, and Payment
            </Text>
            <Text className="para-text">
              We offer various subscription plans for our Services, with fees as
              published on our pricing page. All fees are quoted in US dollars
              and are non-refundable except as expressly provided in these Terms
              or required by law. You agree to pay all fees associated with your
              chosen subscription plan, including any applicable taxes or
              charges.
            </Text>
            <Text className="para-text">
              Payment for our Services is required in advance and will be
              automatically charged to your designated payment method on your
              billing date. You must maintain valid payment information to
              continue accessing our Services. If we are unable to process
              payment through your designated payment method, we may suspend or
              terminate your access to the Services until payment is received.
            </Text>
            <Text className="para-text">
              We reserve the right to modify our fees upon thirty (30) days'
              written notice. If you continue to use the Services after a fee
              change takes effect, you agree to pay the modified fees. If you do
              not agree to a fee change, you must cancel your subscription
              before the change takes effect.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              8. Service Level Commitments and Support
            </Text>
            <Text className="para-text">
              We strive to maintain 99.9% uptime for our Services, excluding
              scheduled maintenance windows. We will provide advance notice of
              scheduled maintenance through our status page or email
              notifications. In the event of unscheduled downtime, we will work
              diligently to restore service as quickly as possible.
            </Text>
            <Text className="para-text">
              Standard technical support is included with all subscriptions and
              is available through our support portal. Response times vary based
              on issue severity and your subscription level. Premium support
              options with enhanced response times and dedicated support
              channels are available for purchase.
            </Text>
            <Text className="para-text">
              We maintain a robust system monitoring and incident response
              program to detect and address service issues promptly. While we
              work to minimize disruptions, we do not guarantee uninterrupted
              access to our Services and are not liable for any service
              interruptions or data losses that may occur.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              9. Intellectual Property Rights and Licenses
            </Text>
            <Text className="para-text">
              Our Services, including but not limited to all software,
              algorithms, user interfaces, text, graphics, logos, trademarks and
              platform technology, are protected by copyright, trademark,
              patent, and other intellectual property laws. These Terms do not
              grant you any rights to our intellectual property except for the
              limited license to use our Services as described herein.
            </Text>
            <Text className="para-text">
              You retain all intellectual property rights in your User Data and
              Outputs. By using our Services, you grant us a worldwide,
              non-exclusive, royalty-free license to host, copy, transmit, and
              display your User Data as necessary to provide and maintain the
              Services. This license extends only to the extent necessary to
              provide the Services and terminates when you remove your User Data
              or close your account.
            </Text>
            <Text className="para-text">
              Any feedback, suggestions, or ideas you provide regarding our
              Services may be used by us without obligation to you. By providing
              such feedback, you grant us a perpetual, irrevocable, worldwide
              license to use and incorporate your feedback into our Services.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              10. Limitation of Liability and Warranties
            </Text>
            <Text className="para-text">
              THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY
              WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY. WE
              SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
            </Text>
            <Text className="para-text">
              We do not warrant that the Services will be uninterrupted,
              error-free, or completely secure. Any material downloaded or
              otherwise obtained through the Services is accessed at your own
              discretion and risk. You are solely responsible for any damage to
              your computer system or loss of data that results from downloading
              any such material.
            </Text>
            <Text className="para-text">
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL ZINKML
              BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
              DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER
              INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR
              INABILITY TO USE, THE SERVICES.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              11. Indemnification
            </Text>
            <Text className="para-text">
              You agree to indemnify, defend, and hold harmless ZinkML and its
              officers, directors, employees, agents, and affiliates from and
              against any claims, liabilities, damages, losses, and expenses,
              including reasonable attorneys' fees, arising out of or in any way
              connected with:
            </Text>
            <Text className="para-text" paddingLeft="1rem">
              <ul>
                <li>Your access to or use of the Services</li>
                <li>Your violation of these Terms</li>
                <li>
                  Your violation of any third-party rights, including privacy or
                  intellectual property rights
                </li>
                <li>Any User Data you submit to the Services</li>
                <li>Your interactions with other users of the Services</li>
              </ul>
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              12. Term, Termination, and Data Deletion
            </Text>
            <Text className="para-text">
              These Terms remain in effect until terminated by either party. You
              may terminate these Terms at any time by discontinuing use of the
              Services and closing your account. We may terminate or suspend
              your access to the Services at any time for any reason without
              notice.
            </Text>
            <Text className="para-text">Upon termination:</Text>
            <Text className="para-text" paddingLeft="1rem">
              <ul>
                <li>
                  All licenses granted under these Terms will immediately
                  terminate
                </li>
                <li>You must cease all use of the Services</li>
                <li>
                  You remain responsible for all fees incurred before
                  termination
                </li>
                <li>
                  We will delete or return your User Data as specified in our
                  data retention policies
                </li>
              </ul>
            </Text>
            <Text className="para-text">
              We may retain certain data as required by law or for legitimate
              business purposes. Any provisions of these Terms that by their
              nature should survive termination shall survive termination.
            </Text>
            <Text className="para-text">
              We may update these Terms of Use from time to time. Any changes
              will be effective upon posting the revised Terms of Use on our
              website. Your continued use of our website, products, or services
              after such changes constitutes your acceptance of the updated
              Terms of Use.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              13. Governing Law and Dispute Resolution
            </Text>
            <Text className="para-text">
              These Terms shall be governed by and construed in accordance with
              the laws of India, without regard to its conflict of law
              provisions. Any dispute arising from these Terms shall be resolved
              exclusively in the courts at Bengaluru, Karnataka, India.
            </Text>
            <Text className="para-text">
              For any dispute not subject to arbitration, you agree to submit to
              the personal and exclusive jurisdiction of the courts located in
              Bengaluru, Karnataka, India. You waive any objection to the venue
              in these courts.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              14. General Provisions
            </Text>
            <Text className="para-text">
              If any provision of these Terms is found to be unenforceable, that
              provision shall be severed from these Terms and shall not affect
              the validity and enforceability of any remaining provisions.
            </Text>
            <Text className="para-text">
              Our failure to enforce any right or provision of these Terms shall
              not be considered a waiver of those rights. No waiver of any
              provision of these Terms will be effective unless in writing and
              signed by an authorized representative of ZinkML.
            </Text>
            <Text className="para-text">
              These Terms constitute the entire agreement between you and ZinkML
              regarding your use of the Services and supersede all prior
              agreements and understandings, whether written or oral.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              15. Contact Information
            </Text>
            <Text className="para-text">
              If you have any questions about these Terms, please contact us at:
            </Text>
            <Text className="para-text">
              Zinkml Technologies Private Limited Company <br />
              WeWork Galaxy, #43, Residency Road, Bengaluru, India - 560025
              <br />
              Email: support@zinkml.com <br />
            </Text>
            <Text
              className="text-lg"
              lineHeight="1.5rem"
              // fontSize={['1.875rem', '2.25rem', '3rem']}
              fontWeight="500"
              bgGradient="radial-gradient(var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              Thank you for choosing ZinkML Technologies Private Limited
              Company. We look forward to serving you as you access our products
              and services.
            </Text>
          </Flex>
        </Grid>
      </Flex>
    </ChakraProvider>
  );
}
