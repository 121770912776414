import { Building, Email, PhoneFilled } from '@carbon/icons-react';
import { Box, ChakraProvider, Flex, Grid, Link, Text } from '@chakra-ui/react';
import react from 'react';

export default function AboutUsContactUs() {
  return (
    <ChakraProvider>
      <Flex
        className="observer3"
        data-id="4"
        id="contactUs"
        width="100vw"
        justify="center"
        paddingY="4rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
        alignItems="center"
      >
        <Text
          lineHeight="1.35"
          fontSize={['30px', '40px', '50px', '50px', '50px']}
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(0deg, var(--slate-500), var(--slate-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          _hover={{
            bgGradient:
              'linear-gradient(0deg, var(--slate-500), var(--slate-300))',
            transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          Contact Us
          <Box
            className="smallDivider"
            width="calc(min(700px, 90%))"
            margin="24px auto"
            bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
          ></Box>
        </Text>
        <Flex
          width="calc(min(900px, 96%))"
          direction="column"
          margin="0 auto"
          gap="4rem"
        >
          <Grid
            templateColumns={[
              'repeat(1,minmax(0,1fr))',
              'repeat(2,minmax(0,1fr))',
              'repeat(2,minmax(0,1fr))',
              'repeat(2,minmax(0,1fr))',
              'repeat(2,minmax(0,1fr))',
            ]}
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="center"
              padding="3rem 1rem 3rem 2rem"
              gap="5px"
              bg="linear-gradient(90deg, #24241e, #1e2424 100%)"
            >
              <Text
                className="text-xl"
                // lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                // paddingBottom="6px"
              >
                For Enquiries
              </Text>
              <Flex
                fontSize="14px"
                fontWeight="400"
                // paddingBottom="6px"
                color="var(--slate-400)"
                alignItems="center"
                gap="5px"
              >
                <Email />
                Email:
                <Link href="mailto:support@zinkml.com" color="var(--blue-400)">
                  support@zinkml.com
                </Link>
                <br />
              </Flex>
              <Flex
                fontSize="14px"
                fontWeight="400"
                color="var(--slate-400)"
                alignItems="center"
                gap="5px"
              >
                <PhoneFilled />
                Mob:
                <Link href="tel:+91 9108 746 691" color="var(--blue-400)">
                  +91 9108746691
                </Link>
              </Flex>
              <Flex
                fontSize="14px"
                fontWeight="400"
                paddingBottom="30px"
                color="var(--slate-400)"
                alignItems="center"
                gap="5px"
              >
                <PhoneFilled />
                Landline:
                <Link href="tel:080 3701 2516" color="var(--blue-400)">
                  080 3701 2516
                </Link>
                <br />
              </Flex>
              <Text
                className="text-xl"
                // lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                // paddingBottom="6px"
              >
                For Investor Relations
              </Text>
              <Flex
                fontSize="14px"
                fontWeight="400"
                // paddingBottom="6px"
                color="var(--slate-400)"
                alignItems="center"
                gap="5px"
              >
                <Email />
                Email:
                <Link
                  href="mailto:investor.relations@zinkml.com"
                  color="var(--blue-400)"
                >
                  investor.relations@zinkml.com
                </Link>
                <br />
              </Flex>
            </Flex>
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="center"
              padding="3rem 1rem 3rem 2rem"
              gap="5px"
              bg="linear-gradient(90deg, #24241e, #1e2424 100%)"
            >
              <Text
                className="text-xl"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Address
              </Text>
              <Flex
                fontSize="14px"
                fontWeight="400"
                paddingBottom="6px"
                color="var(--slate-400)"
                alignItems="center"
                gap="20px"
              >
                <Building /> WeWork Galaxy, <br /> #43, Residency Road <br />{' '}
                Bengaluru - 560025
                <br />
              </Flex>
            </Flex>
          </Grid>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}
