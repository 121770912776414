import { ChakraProvider, Flex, Grid, Link, Text } from '@chakra-ui/react';

// import './Styles.css';

export default function TermsOfUseCommercial() {
  return (
    <ChakraProvider>
      <Flex
        width="calc(min(1080px, 96%))"
        direction="column"
        margin="0 auto"
        gap="4rem"
      >
        <Grid
          templateColumns="repeat(1,minmax(0,1fr))"
          gap="0"
          // paddingY="2rem"
          // paddingX="5"
          padding="0"
          width="100%"
          boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
          borderTop="1px solid var(--slate-800)"
          borderRadius="var(--round-xxl)"
          overflow="hidden"
        >
          <Flex
            gridColumn={['span 12', 'span 12', 'span 9']}
            direction="column"
            justify="center"
            // paddingX="5"
            // paddingY="10"
            padding="3rem 1rem 3rem 2rem"
            gap="5px"
            // maxWidth="450px"
            bg="#1a1e1f"
          >
            <Text className="para-text">
              Commercial Terms of Service: Last Updated on 28th January, 2025
            </Text>
            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              1. Introduction and Service Overview
            </Text>
            <Text className="para-text">
              These Commercial Terms of Service ("Agreement") constitute a
              legally binding contract between ZinkML Technologies Private
              Limited Company ("ZinkML," "we," "us," or "our"), headquartered at
              WeWork Galaxy, #43, Residency Road, Bengaluru, India - 560025, and
              the entity ("Customer," "you," or "your") accessing or using our
              enterprise machine learning platform, services, APIs, and
              associated technologies (collectively, the "Services"). By
              accessing or using our Services, you acknowledge and agree to be
              bound by this Agreement.
            </Text>
            <Text className="para-text">
              Throughout this Agreement, we use certain defined terms. "Customer
              Data" refers to any data, content, code, models, or materials you
              upload to or process through our Services. "Authorized Users"
              means your employees, contractors, and agents whom you permit to
              access the Services. "Output" refers to the results generated from
              processing Customer Data through our platform, including model
              predictions and analytics.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              2. Service License and Access Rights
            </Text>
            <Text className="para-text">
              Subject to your compliance with this Agreement and timely payment
              of applicable fees, ZinkML grants you a non-exclusive,
              non-transferable, worldwide license to access and use the Services
              during your subscription term. This license permits you to deploy
              and manage machine learning models, process Customer Data, access
              analytics tools, generate Outputs, and integrate with authorized
              third-party services, solely for your internal business
              operations.
            </Text>
            <Text className="para-text">
              You shall not license, sell, resell, transfer, assign, distribute,
              or otherwise commercially exploit the Services. Additionally, you
              are prohibited from modifying, decompiling, reverse engineering,
              or creating derivative works based on the Services. Any attempt to
              build competing products using insights from our Services or to
              exceed contractual usage limits is strictly prohibited. We reserve
              the right to monitor Service usage to ensure compliance with these
              restrictions.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              3. Data Rights, Security, and Privacy
            </Text>
            <Text className="para-text">
              Customer retains all ownership rights in Customer Data, including
              any intellectual property rights therein. By using our Services,
              you grant ZinkML a worldwide, non-exclusive, royalty-free license
              to host, copy, transmit, display, and process Customer Data as
              necessary to provide and maintain the Services. This license is
              limited to the purposes described in this Agreement and our &nbsp;
              <Link href="/privacy-policy" color="blue.400">
                Privacy Policy
              </Link>
              .
            </Text>
            <Text className="para-text">
              We implement and maintain comprehensive security measures to
              protect your data, including encryption of data in transit and at
              rest, robust access controls, regular security assessments, and
              incident response procedures. Our security program is designed to
              meet industry standards and regulatory requirements, as detailed
              in our Security Documentation. All employees undergo regular
              security training and are bound by confidentiality obligations.
            </Text>
            <Text className="para-text">
              For customers subject to specific data protection regulations, we
              offer additional compliance features and documentation. Where
              Customer Data includes personal information, we act as a data
              processor under your direction, and our Data Processing Agreement
              (attached as Exhibit A) governs such processing activities.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              4. Enterprise Support and Service Levels
            </Text>
            <Text className="para-text">
              Our enterprise support program provides comprehensive technical
              assistance, including 24/7 emergency support for critical issues,
              dedicated technical account management, and priority issue
              resolution. Enterprise customers receive regular service reviews,
              custom training sessions, and personalized onboarding assistance
              to ensure successful platform adoption.
            </Text>
            <Text className="para-text">
              We commit to maintaining Service availability as specified in our
              Service Level Agreement (SLA). This includes specific uptime
              guarantees, response time commitments, and resolution time
              objectives. In the event we fail to meet these commitments, you
              may be eligible for service credits as detailed in the SLA. We
              provide advance notice of scheduled maintenance and work
              diligently to minimize any service disruptions.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              5. Commercial Terms and Billing
            </Text>
            <Text className="para-text">
              Service fees are specified in your Order Form and are based on the
              Services purchased and actual usage. Payment obligations are
              non-cancelable, and fees paid are non-refundable. Unless otherwise
              specified, fees are invoiced monthly and payable within thirty
              days. Any payment not received within the payment terms will
              accrue interest at the rate of 1.5% per month.
            </Text>
            <Text className="para-text">
              You are responsible for paying all taxes associated with your
              purchases. Fees are exclusive of taxes, except for taxes based on
              ZinkML's net income. If you exceed your purchased usage limits,
              overage fees will be billed in arrears at the rates specified in
              your Order Form.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              6. Intellectual Property Rights and Ownership
            </Text>
            <Text className="para-text">
              ZinkML retains all right, title, and interest in the Services,
              including all related intellectual property rights. Our
              proprietary rights extend to our platform technology,
              infrastructure, machine learning algorithms, models,
              documentation, training materials, and any improvements or
              modifications to the Services. We also retain rights to aggregated
              and anonymized usage data that helps us improve our Services while
              maintaining the confidentiality of your specific Customer Data.
            </Text>
            <Text className="para-text">
              While we maintain ownership of our core technology, you retain all
              rights to your Customer Data, custom models developed using our
              Services, Outputs generated from your data, and your confidential
              information and trade secrets. Any suggestions, ideas, enhancement
              requests, or feedback you provide regarding the Services may be
              used by us without limitation, but this does not grant us any
              rights to your Customer Data or proprietary business information.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              7. Confidentiality and Data Protection
            </Text>
            <Text className="para-text">
              Both parties agree to protect each other's Confidential
              Information with the same degree of care used to protect their own
              confidential information, but no less than reasonable care.
              "Confidential Information" includes technical specifications,
              business plans, pricing information, Customer Data, product
              roadmaps, and any other non-public information disclosed under
              this Agreement. Neither party will disclose the other's
              Confidential Information to any third party except as necessary to
              perform under this Agreement or as required by law.
            </Text>
            <Text className="para-text">
              Your Customer Data receives additional protections beyond standard
              confidentiality measures. We maintain comprehensive data security
              protocols, including encryption, access controls, regular security
              audits, and employee training. In the event of any security
              incident affecting your data, we will promptly notify you and
              cooperate in any investigation or required notifications. Our
              security measures are regularly updated to address emerging
              threats and comply with evolving regulatory requirements.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              8. Warranties and Service Commitments
            </Text>
            <Text className="para-text">
              We warrant that the Services will perform materially in accordance
              with our published documentation under normal use. We also warrant
              that we have implemented reasonable security measures to protect
              your data and that we will provide the Services in compliance with
              applicable laws. Each party warrants that it has the legal
              authority to enter into this Agreement and that its performance
              will not violate any applicable laws or contractual obligations.
            </Text>
            <Text className="para-text">
              However, EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, THE
              SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY
              WARRANTY OF ANY KIND. We specifically disclaim all implied
              warranties, including any warranties of merchantability, fitness
              for a particular purpose, or non-infringement. We do not warrant
              that the Services will be uninterrupted or error-free, although we
              strive to maintain high availability and promptly address any
              issues that arise.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              9. Limitation of Liability
            </Text>
            <Text className="para-text">
              IN NO EVENT WILL EITHER PARTY'S AGGREGATE LIABILITY ARISING OUT OF
              OR RELATED TO THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY
              CUSTOMER FOR THE SERVICES GIVING RISE TO THE LIABILITY IN THE
              TWELVE MONTHS PRECEDING THE FIRST INCIDENT OUT OF WHICH THE
              LIABILITY AROSE. THIS LIMITATION OF LIABILITY SHALL NOT APPLY TO
              LIABILITY ARISING FROM A PARTY'S GROSS NEGLIGENCE OR WILLFUL
              MISCONDUCT.
            </Text>
            <Text className="para-text">
              NEITHER PARTY SHALL BE LIABLE TO THE OTHER PARTY OR ANY THIRD
              PARTY FOR ANY LOSS OF USE, REVENUE, OR PROFIT OR FOR ANY
              CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES
              WHETHER IN CONTRACT, TORT, OR UNDER ANY OTHER THEORY OF LIABILITY,
              EVEN IF THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              10. Term, Termination, and Data Handling
            </Text>
            <Text className="para-text">
              This Agreement commences on the Effective Date and continues until
              all subscriptions have expired or been terminated. Either party
              may terminate this Agreement: (a) upon 30 days written notice of a
              material breach if such breach remains uncured, or (b) immediately
              upon written notice if the other party becomes insolvent or makes
              any assignment for the benefit of creditors.
            </Text>
            <Text className="para-text">
              Upon termination of this Agreement, you will cease all use of the
              Services and pay any outstanding fees. We will make your Customer
              Data available for export during a 30-day period following
              termination. After this period, we will delete or otherwise render
              inaccessible any of your Customer Data that remains in our systems
              or backups, except as required by law or regulation.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              11. Dispute Resolution and Governing Law
            </Text>
            <Text className="para-text">
              This Agreement is governed by the laws of India without regard to
              its conflict of law principles. Any dispute arising from these
              Terms shall be resolved exclusively in the courts at Bengaluru,
              Karnataka, India. The prevailing party in any action to enforce
              this Agreement shall be entitled to recover its reasonable
              attorneys' fees and costs.
            </Text>
            <Text className="para-text">
              Before initiating formal dispute proceedings, the parties agree to
              attempt good-faith resolution through direct negotiation between
              executives with authority to resolve the dispute. This negotiation
              period shall last at least 30 days unless both parties agree to
              extend or shorten it.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              12. General Provisions
            </Text>
            <Text className="para-text">
              This Agreement, including all exhibits and attachments,
              constitutes the entire agreement between the parties and
              supersedes all prior and contemporaneous agreements, proposals, or
              representations, written or oral, concerning its subject matter.
              No modification or amendment of this Agreement shall be binding
              unless in writing and signed by both parties.
            </Text>
            <Text className="para-text">
              Neither party may assign this Agreement without the prior written
              consent of the other party, except that either party may assign
              this Agreement to a successor in connection with a merger,
              acquisition, or sale of all or substantially all of its assets.
              Any attempted assignment in violation of this section shall be
              void.
            </Text>
            <Text className="para-text">
              If any provision of this Agreement is held by a court of competent
              jurisdiction to be contrary to law, such provision shall be
              modified and interpreted to accomplish the objectives of the
              original provision to the greatest extent possible while remaining
              within legal bounds.
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              13. Contact and Notices
            </Text>
            <Text className="para-text">
              All notices under this Agreement must be in writing and will be
              deemed given when: (a) delivered personally, (b) sent by confirmed
              electronic mail, (c) sent by commercial overnight courier, or (d)
              upon receipt when sent by certified or registered mail, return
              receipt requested, postage prepaid.
            </Text>
            <Text className="para-text">Notices should be sent to:</Text>
            <Text className="para-text">
              Zinkml Technologies Private Limited Company <br />
              WeWork Galaxy, #43, Residency Road, Bengaluru, India - 560025
              <br />
              Email: support@zinkml.com <br />
            </Text>

            <Text
              className="text-lg"
              lineHeight="1.5rem"
              fontWeight="500"
              bgGradient="radial-gradient(var(--pink-300), var(--sky-200))"
              bgClip="text"
              paddingBottom="6px"
            >
              Thank you for choosing ZinkML Technologies Private Limited
              Company. We look forward to serving you as you access our products
              and services.
            </Text>
          </Flex>
        </Grid>
      </Flex>
    </ChakraProvider>
  );
}
