import { NextOutline } from '@carbon/icons-react';
import { Box, ChakraProvider, Flex, Grid, Link, Text } from '@chakra-ui/react';
import react, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CtaButton from './CtaButton';
import CtaFooter1 from './CtaFooter1';
import FooterNew from './FooterNew';
import NavComp from './NavComp';
import TermsOfUseConsumer from './TermsOfUseConsumer';
import TermsOfUseCommercial from './TermsOfuseCommercial';
// import './Styles.css';
let ctaIcon = <NextOutline color="var(--teal-300)" size="16" />;
export default function TermsOfUse() {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = tab => {
    setActiveTab(tab);
  };
  return (
    <ChakraProvider>
      <Helmet>
        <title>ZinkML: Terms of Use</title>
        <link rel="canonical" href="https://zinkml.com/terms-of-use" />
        <meta
          name="description"
          content="Please see ZinkML's Terms of Use here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta property="og:title" content="ZinkML: Terms of Use" />
        <meta
          name="og:description"
          content="Please see ZinkML's Terms of Use here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="og:url" content="https://zinkml.com/terms-of-use" />

        <meta property="twitter:title" content="ZinkML: Terms of Use" />
        <meta
          name="twitter:description"
          content="Please see ZinkML's Terms of Use here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="twitter:url" content="https://zinkml.com/terms-of-use" />
      </Helmet>
      <NavComp />
      <Flex
        id="homeTop"
        width="100vw"
        justify="center"
        paddingY="7rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        // gap="2rem"
      >
        <Text
          // fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-5xl"
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(145deg, var(--amber-300), var(--purple-xx-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
        >
          Terms of Use
        </Text>
        <Flex className="tabs" justifyContent="center">
          <button
            className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
            onClick={() => handleTabClick('tab1')}
          >
            Consumer
          </button>
          <button
            className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
            onClick={() => handleTabClick('tab2')}
          >
            Commercial
          </button>
        </Flex>
        <div className="tab-content">
          {activeTab === 'tab1' && (
            <div>
              <TermsOfUseConsumer />
            </div>
          )}
          {activeTab === 'tab2' && (
            <div>
              <TermsOfUseCommercial />
            </div>
          )}
        </div>
      </Flex>
      <CtaFooter1 />
      <FooterNew />
      <style jsx>{`
        .tabs {
          --tabs-padding: var(--sp-1-5) var(--sp-3);
          border-radius: 9999px;
          -webkit-user-select: none;
          user-select: none;
          background: #33415566 padding-box;
          border: 1px solid #0000;
          height: -webkit-max-content;
          height: max-content;
          min-height: 32px;
          margin: 10px auto;
          display: flex;
          overflow: hidden;
          box-shadow: 0 0 #0000, 0 0 #0000, 0 2px 3px #0000001a,
            inset 0 1px #ccddff0f;
        }
        .tab {
          gap: 8px;
          padding: var(--tabs-padding);
          border-radius: 9999px;
          cursor: pointer;
          color: #e0f2fe;
          border: 1px solid #0000;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          display: flex;
          font-size: 13px;
          min-width: 120px;
        }
        .tab.active {
          border: 1px solid #fed7aa99;
          background: linear-gradient(rgb(35, 38, 63), #2f201f) padding-box
            padding-box;
        }
      `}</style>
    </ChakraProvider>
  );
}
