import {
  ArrowDown,
  ArrowUp,
  Clean,
  CloudDataOps,
  ContainerImagePush,
  DataCategorical,
  DocumentMultiple_01,
  FunctionMath,
  QuadrantPlot,
  Rocket,
  VolumeBlockStorage,
} from '@carbon/icons-react';
import { ChakraProvider, Flex, Grid, Show, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
// import FeatureSliders from './HomeFeatureSliders';
import gsap from 'gsap';
import homeDiagramGif from './home-diagram.gif';
import homeDiagramMp4 from './home-diagram.mp4';
import homeDiagramOgg from './homeDiagram.ogg';
import homeDiagramWebm from './homeDiagram.webm';

// let delay = '0s';

export const links = [
  {
    text: 'Local Files',
    link: '#connect',
    icon: (
      <DocumentMultiple_01
        id="local"
        height="100%"
        width="100%"
        fill="var(--sky-300)"
      />
    ),
    color: 'var(--sky-300)',
    visibility: 'visible',
    row: [1, 2],
    column: [1, 2],
    transform: 'translateX(10px) rotate(45deg)',
  },
  {
    text: 'AWS, GCP, Azure',
    link: '#connect',
    icon: (
      <CloudDataOps
        id="cloud"
        height="100%"
        width="100%"
        fill="var(--sky-300)"
      />
    ),
    color: 'var(--sky-300)',
    visibility: 'visible',
    row: [2, 3],
    column: [1, 2],
    transform: 'translateX(10px) rotate(0deg)',
  },
  {
    text: 'Hybrid Connect',
    link: '#connect',
    icon: (
      <VolumeBlockStorage
        id="hybrid"
        height="100%"
        width="100%"
        fill="var(--sky-300)"
      />
    ),
    color: 'var(--sky-300)',
    visibility: 'visible',
    row: [3, 4],
    column: [1, 2],
    transform: 'translateX(10px) rotate(-45deg)',
  },
  // {
  //   text: 'Connect',
  //   link: '#connectH',
  //   icon: (
  //     <IbmCloudDirectLink_2Connect
  //       id="connect"
  //       height="100%"
  //       width="100%"
  //       fill="var(--sky-300)"
  //     />
  //   ),
  // color: 'var(--orange-200)',
  //   visibility: 'visible',
  //   row: [1, 2],
  //   column: [1, 3],
  // },

  {
    text: 'Clean',
    link: '#clean1',
    icon: (
      <Clean
        id="clean"
        height="100%"
        width="100%"
        fill="var(--purple-xx-200)"
      />
    ),
    color: 'var(--purple-xx-200)',
    visibility: 'visible',
    row: [2, 3],
    column: [2, 3],
    transform: 'translateX(10px) rotate(0deg)',
  },
  {
    text: 'Transform',
    link: '#transform1',
    icon: (
      <FunctionMath
        id="transform"
        height="100%"
        width="100%"
        fill="var(--orange-200)"
      />
    ),
    color: 'var(--orange-200)',
    visibility: 'visible',
    row: [2, 3],
    column: [3, 4],
    transform: 'translateX(10px) rotate(0deg)',
  },

  {
    text: 'Model',
    link: '#model1',
    icon: (
      <DataCategorical
        id="model"
        height="100%"
        width="100%"
        fill="var(--purple-xx-200)"
      />
    ),
    color: 'var(--purple-xx-200)',
    visibility: 'visible',
    row: [2, 3],
    column: [4, 5],
    transform: 'translateX(10px) rotate(0deg)',
  },
  {
    text: 'Deploy',
    link: '#deploy1',
    icon: (
      <Rocket id="deploy" height="100%" width="100%" fill="var(--teal-300)" />
    ),
    color: 'var(--teal-300)',
    visibility: 'hidden',
    row: [1, 2],
    column: [5, 6],
    transform: 'translateX(-32px) translateY(38px) rotate(90deg)',
  },
  {
    text: 'Visualize',
    link: '#visualize1',
    icon: (
      <QuadrantPlot
        id="visualize"
        height="100%"
        width="100%"
        fill="var(--teal-300)"
      />
    ),
    color: 'var(--teal-300)',
    visibility: 'hidden',
    row: [3, 4],
    column: [5, 6],
    transform: 'translateX(-32px) translateY(-38px) rotate(-90deg)',
  },
];

export function HomeDiagramWeb() {
  useEffect(() => {
    let tl = gsap.timeline({ repeat: -1, repeatDelay: 1, yoyo: true });
    tl.fromTo(
      '#local',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right0', { x: 60, y: 60, rotate: 45, duration: 0.5 });
    tl.fromTo(
      '#cloud',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right1', { x: 60, duration: 0.5 });
    tl.fromTo(
      '#hybrid',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right2', { x: 60, y: -60, duration: 0.5 });
    tl.fromTo(
      '#clean',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right3', { x: 60, duration: 0.5 });
    tl.fromTo(
      '#transform',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right4', { x: 60, duration: 0.5 });
    tl.fromTo(
      '#model',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right5', { x: 60, y: 0, duration: 0.5 });
    // tl.fromTo(
    //   '#transform',
    //   { scale: 0.8 },
    //   { scale: 1.2, duration: 1, ease: 'back.inOut(1.7)' }
    // );
    // tl.to('#right7', { x: -140, y: 0, duration: 1 });
    tl.fromTo(
      '#deploy',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.fromTo(
      '#visualize',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
  });
  return (
    <ChakraProvider>
      <Flex
        // transform="rotate(90deg)"
        width="100vw"
        maxW="96%"
        margin="0 auto"
        // paddingY="12"
        // paddingX="5"
        justifyContent="center"
        className="observer1"
        data-id="2"
        id="ProductFlow"
        borderRadius="20px"
        // borderTopRadius={['0', '10px', '20px', '30px', '30px']}
        // alignItems="center"
        flexDir="column"
        overflow="hidden"
        paddingBottom="24px"
        paddingTop="24px"
      >
        <Text
          style={{
            width: 'max-content',
            height: 'max-content',
            alignItems: 'center',
            justifyContent: 'center',
            top: '0',
            left: '0',
            padding: '1rem',
            margin: 'auto',
          }}
          //fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-4xl"
          fontWeight="600"
          letterSpacing="-.015em"
          background="linear-gradient(180deg, var(--pink-300) 30%, var(--sky-400) 100%)"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          textShadow="0 1px #00000029"
          _hover={{
            // transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          All-in-One Toolkit
        </Text>
        <Flex
          // transform="rotate(90deg) translateX(0) translateY(0px)"
          // padding="0"
          // minHeight="1000px"
          width="max-content"
          margin="0 auto"
          maxWidth="calc(min(100%, 1600px))"
          paddingY="8"
          paddingX="5"
          justifyContent="center"
          flexDirection="column"
          gap="5"
        >
          <Flex
            height="25px"
            width="calc(100% - 120px)"
            border="1px solid var(--pink-400)"
            borderBottomColor="transparent"
            borderTopRadius="10px"
            alignSelf="center"
            justifyContent="flex-end"
          >
            <Flex transform="translateX(8.5px) translateY(22px)">
              <ArrowDown fill="var(--pink-400)" />
            </Flex>
          </Flex>
          <Grid
            templateColumns="repeat(5,minmax(0,1fr))"
            gap="5px"
            columnGap="5rem"
            templateRows="repeat(3, 1fr)"
          >
            {links.map((el, index) => {
              return (
                <Flex
                  className="productBox"
                  // transform="rotate(-90deg)"
                  gridColumnStart={el.column[0]}
                  gridColumnEnd={el.column[1]}
                  gridRowStart={el.row[0]}
                  gridRowEnd={el.row[1]}
                  as="a"
                  flexDirection="column"
                  href={el.link}
                  gap="2"
                  paddingBottom="3"
                  color="var(--slate-300)"
                  _hover={{ color: el.color }}
                  maxWidth="150px"
                  // maxHeight="100px"
                >
                  <Text
                    alignSelf="center"
                    className="text-sm"
                    letterSpacing="0.01em"
                    fontSize="0.825rem"
                    fontWeight="400"
                    color="var(--teal-300)"
                    padding="0 5px"
                  >
                    {el.text}
                  </Text>
                  <Flex
                    border="2px solid "
                    className="iconBorder"
                    borderColor="var(--gray-300)"
                    // bgClip="border"
                    style={{
                      background:
                        'linear-gradient(#000000d9,#000000d3) padding-box,linear-gradient(to bottom,var(--orange-200),var(--sky-300),var(--sky-400)) border-box',
                      border: '1px solid transparent',
                      borderRadius: '16px',
                    }}
                    _hover={{ borderColor: el.color }}
                    // borderRadius="10px"
                    height="120px"
                    justifyContent="center"
                    padding="3"
                    alignItems="center"
                    position="relative"
                    // _after={{
                    //   content: '">"',
                    //   transform: 'translateX(40px)',
                    // }}
                  >
                    <Flex
                      height="60%"
                      width="60%"
                      alignSelf="center"
                      transform="translateX(8px)"
                      _hover={{
                        transform:
                          'translateX(8px) translateZ(8px) scale(1.05)',
                      }}
                      // position="absolute"
                    >
                      {el.icon}
                    </Flex>

                    <Flex
                      id={'right' + index}
                      className="forwardMoving"
                      visibility={el.visibility}
                      transform={el.transform}

                      // animation="slideIn 3s forwards infinite"
                      // alignSelf="flex-end"
                      // animationDelay={el.delay}
                    >
                      <ContainerImagePush color={el.color} size={24} />
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Grid>
          <Flex
            height="25px"
            width="calc(100% - 120px)"
            border="1px solid var(--pink-400)"
            borderTopColor="transparent"
            borderBottomRadius="10px"
            alignSelf="center"
            justifyContent="flex-start"
            // transform="translateX(50px)"
          >
            <Flex transform="translateX(-8.5px) translateY(-14px)">
              <ArrowUp fill="var(--pink-400)" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export function HomeDiagramMobile() {
  useEffect(() => {
    let tl = gsap.timeline({ repeat: -1, repeatDelay: 1, yoyo: true });
    tl.fromTo(
      '#local',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right0', { x: 60, y: 60, rotate: 45, duration: 0.5 });
    tl.fromTo(
      '#cloud',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right1', { x: 60, duration: 0.5 });
    tl.fromTo(
      '#hybrid',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right2', { x: 60, y: -60, duration: 0.5 });
    tl.fromTo(
      '#clean',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right3', { x: 60, duration: 0.5 });
    tl.fromTo(
      '#transform',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right4', { x: 60, duration: 0.5 });
    tl.fromTo(
      '#model',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.to('#right5', { x: 60, y: 0, duration: 0.5 });
    // tl.fromTo(
    //   '#transform',
    //   { scale: 0.8 },
    //   { scale: 1.2, duration: 1, ease: 'back.inOut(1.7)' }
    // );
    // tl.to('#right7', { x: -140, y: 0, duration: 1 });
    tl.fromTo(
      '#deploy',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
    tl.fromTo(
      '#visualize',
      { scale: 0.8 },
      { scale: 1.2, duration: 0.5, ease: 'back.inOut(1.7)' }
    );
  });
  return (
    <ChakraProvider>
      <Flex
        // transform="rotate(90deg)"
        width="100vw"
        maxW="96%"
        margin="0 auto"
        // paddingY="12"
        // paddingX="5"
        justifyContent="center"
        className="observer1"
        data-id="2"
        id="ProductFlow"
        borderRadius="20px"
        // borderTopRadius={['0', '10px', '20px', '30px', '30px']}
        // alignItems="center"
        flexDir="column"
        overflow="hidden"
        paddingBottom="24px"
        paddingTop="24px"
      >
        <Text
          style={{
            width: 'max-content',
            height: 'max-content',
            alignItems: 'center',
            justifyContent: 'center',
            top: '0',
            left: '0',
            padding: '1rem',
            margin: 'auto',
          }}
          //fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-4xl"
          fontWeight="600"
          letterSpacing="-.015em"
          background="linear-gradient(180deg, var(--pink-300) 30%, var(--sky-400) 100%)"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          textShadow="0 1px #00000029"
          _hover={{
            // transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          All-in-One Toolkit
        </Text>
        <Flex
          transform="rotate(90deg) translateX(20px) translateY(0px)"
          padding="0"
          minHeight="1000px"
          width="max-content"
          margin="0 auto"
          maxWidth="calc(min(100%, 1600px))"
          // paddingY="8"
          // paddingX="5"
          justifyContent="center"
          flexDirection="column"
          gap="5"
        >
          <Flex
            height="25px"
            width="calc(100% - 120px)"
            border="1px solid var(--pink-400)"
            borderBottomColor="transparent"
            borderTopRadius="10px"
            alignSelf="center"
            justifyContent="flex-end"
          >
            <Flex transform="translateX(8.5px) translateY(22px)">
              <ArrowDown fill="var(--pink-400)" />
            </Flex>
          </Flex>
          <Grid
            templateColumns="repeat(5,minmax(0,1fr))"
            gap="5px"
            columnGap="5rem"
            templateRows="repeat(3, 1fr)"
          >
            {links.map((el, index) => {
              return (
                <Flex
                  className="productBox"
                  // transform="rotate(-90deg)"
                  gridColumnStart={el.column[0]}
                  gridColumnEnd={el.column[1]}
                  gridRowStart={el.row[0]}
                  gridRowEnd={el.row[1]}
                  as="a"
                  flexDirection="column"
                  href={el.link}
                  gap="2"
                  paddingBottom="3"
                  color="var(--slate-300)"
                  _hover={{ color: el.color }}
                  maxWidth="150px"
                  // maxHeight="100px"
                >
                  <Text
                    alignSelf="center"
                    className="text-sm"
                    letterSpacing="0.01em"
                    fontSize="0.825rem"
                    fontWeight="400"
                    color="var(--teal-300)"
                    padding="0 5px"
                    transform="rotate(-90deg) translate(-75px, -75px)"
                  >
                    {el.text}
                  </Text>
                  <Flex
                    border="2px solid "
                    className="iconBorder"
                    borderColor="var(--gray-300)"
                    // bgClip="border"
                    style={{
                      background:
                        'linear-gradient(#000000d9,#000000d3) padding-box,linear-gradient(to bottom,var(--orange-200),var(--sky-300),var(--sky-400)) border-box',
                      border: '1px solid transparent',
                      borderRadius: '16px',
                    }}
                    _hover={{ borderColor: el.color }}
                    // borderRadius="10px"
                    height="120px"
                    justifyContent="center"
                    padding="3"
                    alignItems="center"
                    position="relative"
                    // _after={{
                    //   content: '">"',
                    //   transform: 'translateX(40px)',
                    // }}
                  >
                    <Flex
                      height="60%"
                      width="60%"
                      alignSelf="center"
                      transform="translateX(8px) rotate(-90deg)"
                      _hover={{
                        transform:
                          'translateX(8px) translateZ(8px) scale(1.05) rotate(-90deg)',
                      }}
                      // position="absolute"
                    >
                      {el.icon}
                    </Flex>

                    <Flex
                      id={'right' + index}
                      className="forwardMoving"
                      visibility={el.visibility}
                      transform={el.transform}

                      // animation="slideIn 3s forwards infinite"
                      // alignSelf="flex-end"
                      // animationDelay={el.delay}
                    >
                      <ContainerImagePush color={el.color} size={24} />
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Grid>
          <Flex
            height="25px"
            width="calc(100% - 120px)"
            border="1px solid var(--pink-400)"
            borderTopColor="transparent"
            borderBottomRadius="10px"
            alignSelf="center"
            justifyContent="flex-start"
            // transform="translateX(50px)"
          >
            <Flex transform="translateX(-8.5px) translateY(-14px)">
              <ArrowUp fill="var(--pink-400)" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default function HomeDiagram() {
  return (
    <>
      <Show above="980px">
        <HomeDiagramWeb />
      </Show>
      <Show below="980px">
        <Flex
          // transform="rotate(90deg)"
          width="100vw"
          maxW="96%"
          margin="0 auto"
          // paddingY="12"
          // paddingX="5"
          justifyContent="center"
          className="observer1"
          data-id="2"
          id="ProductFlow"
          borderRadius="20px"
          // borderTopRadius={['0', '10px', '20px', '30px', '30px']}
          // alignItems="center"
          flexDir="column"
          overflow="hidden"
          paddingBottom="12px"
          paddingTop="24px"
        >
          <Text
            style={{
              width: 'max-content',
              height: 'max-content',
              alignItems: 'center',
              justifyContent: 'center',
              top: '0',
              left: '0',
              padding: '1rem',
              margin: 'auto',
            }}
            //fontSize={['40px', '40px', '50px', '60px', '60px']}
            lineHeight="1.35"
            className="text-2xl"
            fontWeight="600"
            letterSpacing="-.015em"
            background="linear-gradient(180deg, var(--pink-300) 30%, var(--sky-400) 100%)"
            bgClip="text"
            transition="all ease-in-out 300ms"
            textAlign="center"
            textShadow="0 1px #00000029"
            _hover={{
              // transform: 'translatez(10px) scale(1.02)',
              opacity: '1',
            }}
          >
            All-in-One Toolkit
          </Text>
        </Flex>
        <Flex
          gridColumn={['span 12', 'span 6', 'span 6']}
          bg="rgb(21,23,25)"
          // bg="linear-gradient(45deg, black, transparent)"
          borderRadius="10px"
          minH={['none', 'nonez', '340px', '340px', '340px']}
          padding="0"
          overflow="hidden"
          justifyContent="center"
          margin="0 10px"
        >
          <video autoPlay={true} loop={true} muted={true}>
            <source src={homeDiagramWebm} type="video/webm" />
            <source src={homeDiagramOgg} type="video/ogg" />
            <source src={homeDiagramMp4} type="video/mp4" />
          </video>
          {/* <img
            src={homeDiagramGif}
            style={{ objectFit: 'cover', alignSelf: 'center' }}
          /> */}
          {/* <video controls loop autoPlay>
              <source src={props.item.subItems[0].subVideo}></source>
            </video> */}
        </Flex>
      </Show>
    </>
  );
}
